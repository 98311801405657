@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);

body {
  background-color:white !important;
  font-family: 'Open Sans', sans-serif;
  color:rgb(136, 136, 136);
}

.navbar {
  padding-top: 0.8em !important;
  padding-bottom: 0.8em !important;
  padding-left: 4em !important;
  padding-right: 4em !important;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 99999 !important;
}

.nav-link-1 {
  font-size: 0.8em !important;
  font-weight: 600 !important;
  -webkit-transition: 0.3s !important;
  transition: 0.3s !important;
  color:white !important;
  border-bottom: 1px solid transparent !important;
}

.nav-link-1:hover {
  color:rgb(242,137,32) !important;
  border-bottom: 1px solid rgb(242,137,32) !important;
}

.fa-facebook {
  font-size: 16px !important;
}

.fa-twitter {
  font-size: 16px !important;
}

.fa-instagram {
  font-size:  16px !important;
}

.carousel-special-props {
  position: relative;
}

.carousel-mask {
  position: absolute;
  bottom:0;
  left: 0;
  width:100%;
  height: 100%;
  z-index: 999;
  background-color:transparent;
  background: url(/static/media/mask-1.5b0183d6.svg) bottom center no-repeat;
  background-size: cover;
}

.carousel-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  z-index: 99;
}

.carousel-control-prev {
  z-index: 999 !important; 
}

.carousel-control-prev span {
}

.carousel-control-next span {
}

.carousel-control-next {
  z-index: 999 !important;
}

.circle-center {
  position: absolute;
  width: 70px;
  height:70px;
  border-radius: 50%;
  background-color: rgb(236, 236, 236);
  top:100%;
  left: 50%;
  -webkit-transform: translate(-50%,-70%);
          transform: translate(-50%,-70%);
  z-index: 9999;
}

.circle-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.circle-blue {
  position: absolute;
  top:50%;
  left:50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  width: 45%;
  height: 70%;
  background-color: rgb(40,50,134);
  border-radius: 20px;
}

.circle-content-anim {
  position: relative;
  width: 100%;
  height: 100%;
}

.circle-anim {
  position: absolute;
  top:15%;
  left: 50%;
  -webkit-transform: translate(-50%,0);
          transform: translate(-50%,0);
  width: 15%;
  height:20%;
  border-radius: 5px;
  background-color: white;
  -webkit-animation-name: fade-out;
          animation-name: fade-out;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes fade-out {
  0%{top:15%; opacity: 1;}
  100%{top:85%; opacity: 0;}
}

@keyframes fade-out {
  0%{top:15%; opacity: 1;}
  100%{top:85%; opacity: 0;}
}

.flex-center {
  display: flex;
  justify-content: center;
}

.special-text-1 {
  font-size: 16px;
}

.color-white {
  color:white;
}



.bg-contact {
  background: url(/static/media/backgroundContact.17a1afdd.png) center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  }

.header-1 {
  font-weight: bold !important;
  letter-spacing: 2px;
  border-left: 4px solid rgb(242,137,32);
}

.header-2 {
  font-weight: bold !important;
  letter-spacing: 2px;
  color:white !important;
  border-right: 4px solid rgb(242,137,32);
  text-align: right;
}

.header-3 {
  font-weight: 700 !important;
  letter-spacing: 2px !important;
  letter-spacing: 2px;
}

.float {
  display: inline;
  float: left;
}

.sub-header {
  color: rgb(242,137,32);
}

.padding-1 {
  padding:5em;
}

.padding-2 {
  padding:3em;
}

.bg-1 {
  background-color: rgb(33,49,49);
}

.bg-2 {
  background-color: rgb(121,121,121);
}

.bg-3 {
  background-color:rgb(242,137,32);
}

.bg-4 {
  background-color: white;
}

.bg-5 {
  background-color: rgb(238, 238, 238);
}




.catalog-products {
  z-index: 99 !important;
  -webkit-transform: translate(20%,10%);
          transform: translate(20%,10%);
  background-color: white;
}

.img-products {
  width: 400px;
  height: 600px;
  z-index: 9;
}

.orange-circle {
  background-color: rgb(242,137,32);
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.flex-center-2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-3 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.highlight-product-panel {
  background-color: rgb(242,137,32);
  max-width: 50%;
  position: relative;
}

.img-highlight-product {
  position: absolute;
  top:50%;
  left: 100%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}

.img-highlight-product img {
  width: 500px;
}

.special-text-2 {
  color:rgb(70, 70, 70);
}

.form-control-custom {
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 1.2px solid rgb(185, 185, 185) !important;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.form-group label {
  font-size: 16px !important;
}

.form-control-custom:focus {
  box-shadow:none !important;
  border: none !important;
  border-bottom: 1.2px solid rgb(242,137,32) !important;
  outline: 0 none !important;
}



.btn-custom {
  background-color: rgb(242,137,32) !important;
  color:white !important
}

.btn-custom:active {
  border-color: rgb(242,137,32) !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgb(242,137,32) !important;
  outline: 0 none;
}

.btn-custom:focus {
  border-color: rgb(242,137,32) !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgb(242,137,32) !important;
  outline: 0 none;
}

.contact-section {
  position: relative;
}

.auth-panel {
  width: 600px;
}

.carrusel-img {
  width: 389.4px;
  height: 200px;
  border-radius: 10px;
  border:1px solid lightgray;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.mark-img {
  width: 255px;
  height: 142px;
  border-radius: 10px;
  border:1px solid lightgray;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

/**  LOADING **/

.loading-data {
  
}

.main-loading {
  position: fixed;
  top:50%;
  left:50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  -webkit-animation-name: beating;
          animation-name: beating;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes beating {
  0%{opacity: 1;}
  50%{ opacity: 0.4;}
  100%{opacity: 1;}
}

@keyframes beating {
  0%{opacity: 1;}
  50%{ opacity: 0.4;}
  100%{opacity: 1;}
}

.spinner-wrapper {
  position:fixed;
  bottom:2em;
  right:2em;
  height:6em;
  width: 6em;
  -webkit-animation-name: show_spinner;
          animation-name: show_spinner;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

@-webkit-keyframes show_spinner {
  0%{opacity: 0;}
  100%{opacity: 1;}
}

@keyframes show_spinner {
  0%{opacity: 0;}
  100%{opacity: 1;}
}

.spinner-custom {
  border-radius: 50%;
  border-width: 6px;
  border-style: solid;
  border-color: white rgb(242,137,32) white rgb(242,137,32) ;
  -webkit-animation-name: spinner_action;
          animation-name: spinner_action;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  height:6em;
  width: 6em;
}

@-webkit-keyframes spinner_action {
  0%{-webkit-transform: rotate(0deg);transform: rotate(0deg);}
  50%{-webkit-transform: rotate(180deg);transform: rotate(180deg);}
  100%{-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}

@keyframes spinner_action {
  0%{-webkit-transform: rotate(0deg);transform: rotate(0deg);}
  50%{-webkit-transform: rotate(180deg);transform: rotate(180deg);}
  100%{-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}

.complete-loading-wrapper {
  position: fixed;
  top:0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0,0,0,0.8);
  z-index: 9999999;
}

.complete-loading {
  color:rgb(242,137,32);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  -webkit-animation-name: fade_in_out;
          animation-name: fade_in_out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}


@-webkit-keyframes fade_in_out {
  0%{opacity: 0.5;}
  50%{opacity: 1;}
  100%{opacity: 0.5;}
}


@keyframes fade_in_out {
  0%{opacity: 0.5;}
  50%{opacity: 1;}
  100%{opacity: 0.5;}
}

.news {
  min-height: 250px;
}

.min-height {
  min-height: 250px;
}

.inbox-item {
  padding:1em;
  border:1px solid lightgray;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.inbox-item-date{
  font-size: 10px !important;
  position: absolute;
  top:1em;
  right: 1em;
}

.inbox-item-email {
  font-size: 12px !important;
}

.inbox-item-issue {
  margin-top: 0.4em;
}

.inbox{
  background-color:rgb(255, 217, 0);
  padding:2px;
  border-radius:50%;
  width:20px;
  height:20px;
  color:black !important;
  position: absolute;
  top:100%;
  left:100%;
  -webkit-transform: translate(-100%, -20%);
          transform: translate(-100%, -20%);
  text-align: center;
  display:none;
}

@media screen and (max-width:800px){
  .navbar {
    padding-left: 1em !important;
    padding-right: 1em !important;
    background-color: rgb(40,50,134) !important; 
  }

  .highlight-product-panel {
    max-width: 100% !important;
  }

  .img-highlight-product {
    top:100% !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

  }

  .img-highlight-product  img {
    width:200px !important;
  }

  .padding-1 {
    padding:1.5em
  }

  .navbar .navbar-brand img{
    width: 140px !important;
  }
}
